import Vue from 'vue';
import Vuex from 'vuex';

import global from "@store/modules/global";
import cms from "@store/modules/cms";
import cart from "@store/modules/cart";
import refillCart from "@store/modules/refillCart";
import customer from "@store/modules/customer";
import zendesk from "@store/modules/zendesk";
import modal from "@store/modules/modal";
import siteNav from "@store/modules/siteNav";
import colorAdvisor from "@store/modules/colorAdvisor";
import partial from "@store/modules/partial";
import coupons from "@store/modules/coupons";
import chatWidget from "@store/modules/chatWidget";
import hairColorBarBooking from "@store/modules/hairColorBarBooking";

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    modules: {
      global,
      cms,
      cart,
      refillCart,
      customer,
      zendesk,
      modal,
      siteNav,
      colorAdvisor,
      partial,
      coupons,
      chatWidget,
      hairColorBarBooking
    }
  });
}