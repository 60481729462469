<template lang="pug">
  .wrap
    .fog(:class="{open: accountNavOpen}" @click.self="toggleAccountNav")
    .side-drawer-wrapper.ui-color-1-bg(v-if="accountNavItems" :class="{open: accountNavOpen}")
      .close-icon.pl-100m.pt-100m
        span.icon-x(@click.stop="toggleAccountNav")
      .hero.xs-f-medium
        .welcome-message.xs-f-large.max-at-tweak
          span {{ welcomeMessage }}
          span.bold {{ customerName }}

      .credits
        RewardsBox.mb-100m

      .list
        .list-item(v-for="item in items" :key="item.url.href")
          mr-icon.mr-100m(v-if="item.iconName" :name="item.iconName" :fill="!!item.iconFill")
          a.ui-color-9.xs-f-medium.max-at-tweak(:href="item.url.href" @click.prevent="navigate(item.url.text, item.url.href)") {{ item.url.text }}

      .logout.xs-12.text-left.pl-200m
        a.underline.xs-f-small.ui-color-9(@click.prevent="confirmLogout") Log Out
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import customerMixin from "@mixins/customerMixin";
  import RewardsBox from '@components/MrNavigation/RewardsBox';

  export default {
    name: "AccountSideDrawerModal",

    components: {
      RewardsBox
    },

    mixins: [
      customerMixin
    ],

    inject: ['getObjProperty'],

    computed: {
      ...mapState('customer', ['info', 'cdata']),
      ...mapState('siteNav', ['accountNavOpen', 'accountNavItems']),

      items() {
        const items = this.getObjProperty(this, 'accountNavItems.items') || [];

        const filteredItems = items.filter(item => {
          return (!item.url?.href?.includes('/dashboard/subscriptions') || this.showSubscriptions);
        });

        return filteredItems;
      },

      welcomeMessage() {
        return "Welcome Back" + (this.customerName ? "," : "");
      },

      customerName() {
        return this.info.first_name;
      },

      showSubscriptions() {
        return (this.cdata.activeSubscriber && this.hasNonMembershipSubs) || this.hasMoreThanCanceledLimitlessMembership;
      },

      activeCdataSubs() {
        return this.getObjProperty(this, 'cdata.activeSubscriptions') || [];
      },

      hasNonMembershipSubs() {
        const membershipTypes = ['renewable_cb_membership', 'omni_memb', 'color_plus_membership'];
        return Boolean(this.activeCdataSubs.filter(subs => !membershipTypes.includes(subs.productType)).length);
      },

      hasMoreThanCanceledLimitlessMembership() {
        let canceledSubTypes = this.getObjProperty(this, 'cdata.canceledSubTypes') || {};
        return Boolean(Object.keys(canceledSubTypes).filter(subType => subType !== 'renewable_cb_membership').length);
      },
    },

    mounted() {
      if (!this.accountNavItems) {
        this.fetchAccountMenuItems();
      }
    },

    methods: {
      ...mapActions('siteNav', ['toggleAccountNav', 'fetchAccountMenuItems']),
      ...mapActions('modal', ['showPrompt']),

      navigate(item, url) {
        this.trackMREventAndRedirect(`Account menu item ${item} clicked`, url);
        this.toggleAccountNav();
      },


      confirmLogout() {
        let promptPayload = {
          component: 'ConfirmDialogTemplateModal',
          theme: 'flex-width',
          confirm: this.mix_logOut,
          confirmText: 'Log Out',
          title: 'Log Out',
          text: 'Please confirm you want to sign out of your Madison Reed account.',
          centerText: true,
        };

        this.showPrompt(promptPayload);
      },


    }
  };
</script>

<style scoped lang="stylus">
  .fog
    position fixed
    top 0
    left 0
    width 0vw
    height 100vh
    background-color #00000050
    transform translateX(100%)
    opacity 0
    transition opacity 0.4s

    &.open
      opacity 1
      transform none
      width 100vw

 .side-drawer-wrapper
    overflow-y scroll
    position fixed
    top 0
    right 0
    height 100%
    border-top-left-radius 16px
    border-bottom-left-radius 16px
    display flex
    flex-direction column
    align-items center
    transform translateX(100%)
    transition transform 0.4s
    min-width 300px


    &.open
      transform translateX(0)

    .close-icon
      display flex
      width 100%
      justify-content flex-start
      span
        cursor pointer
        font-size 26px

    .hero
      position relative
      width 100%

      .welcome-message
        width 100%
        display flex
        flex-direction column
        align-items flex-start
        justify-content center
        padding 2rem 2rem


      span
        z-index 1

    .credits
      width 100%

    .list
      width 100%
      padding 1rem 2rem
      gap 1.7em
      display flex
      flex-direction column

      .list-item
        display flex
        align-items center

        svg
          width 24px
          height 24px

    .logout
      padding-top 1.5em

  @media mq-mobile
    .side-drawer-wrapper
      min-width 85vw

</style>

<style lang="stylus">


</style>