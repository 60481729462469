// Register global mixins, components and plugins only once, otherwise we can create memory leaks
// https://github.com/vuejs/vue/issues/5089#issuecomment-284260111
import Vue from 'vue';

// Plugins
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';
import FocusLock from '@components/FocusLock';
import AsyncComputed from 'vue-async-computed';
import VueSocialSharing from 'vue-social-sharing';
import vClickOutside from 'v-click-outside';

// Components
import MrIcon from '@components/MrIcon';
import imgBox from '@components/imgBox';
import CmsExposeSettings from '@components/CmsExposeSettings';
import party from '@components/party';
import toggle from '@components/toggle';
import slick from '@components/SlickCarouselSsr';
import ResponsiveImage from '@components/ResponsiveImage';
import AddPromo from '@components/AddPromo';
import MrSpinnerVeil from '@components/MrSpinnerVeil';
import TransitionExpand from '@components/TransitionExpand';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';

// Mixins
import globalMixins from '@mixins/globalMixins';

// Filters
import filters from "../filters";
// Directives
import mrScrollAnimation from "../directives/mrScrollAnimation";

// Flag for avoiding to re-inject all of these "Vue.X" methods into the same Vue instance, creating a memory leak
// https://github.com/vuejs/vue/issues/5089#issuecomment-284260111
if (!Vue.globalsSsrRegistered) {
  Vue.globalsSsrRegistered = true;

  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
  });

  Vue.use(Vuelidate);
  Vue.use(VueCookies);
  Vue.use(AsyncComputed);
  Vue.use(VueSocialSharing);
  Vue.use(vClickOutside);

  Vue.mixin(globalMixins);

  Vue.component('focus-lock', FocusLock);
  Vue.component('mr-icon', MrIcon);
  Vue.component('img-box', imgBox);
  Vue.component('cms-expose-settings', CmsExposeSettings);
  Vue.component('party', party);
  Vue.component('toggle', toggle);
  Vue.component('slick', slick);
  Vue.component('responsive-image', ResponsiveImage);
  Vue.component('AddPromo', AddPromo);
  Vue.component('MrSpinnerVeil', MrSpinnerVeil);
  Vue.component('TransitionExpand', TransitionExpand);

  Vue.directive('mrscrollanimation', mrScrollAnimation);

  // We install `vue-svgicon` as plugin
  // and configure it to prefix all CSS
  // classes with `mr-icon-`.
  Vue.use(VueSvgIconPlugin, {
    tagName: 'svgicon',
    classPrefix: 'mr'
  });

}